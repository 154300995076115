import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";
import { FallbackLocaleInputType } from "~/zeus";

const BookmarkItemsQuery = typedGql("query")({
    BookmarkItems: [
    {
      locale: $("locale", "LocaleInputType"),
      fallbackLocale: FallbackLocaleInputType.zh,
      limit: $("limit", "Int"),
      page: $("page", "Int"),
      where: $("where", "Bookmark_where"),
    },
    {
      docs: {
        id: true,
        item: [{},
        {
            relationTo: true,
            value: {
                "...on Event": {
                    id: true,
                },
                "...on Location": {
                    id: true,
                },
                "...on Post": {
                  id: true,
              }
            }
        }]
      },
      totalDocs: true,
      limit: true,
      totalPages: true,
      page: true,
    },
  ],
});

export default BookmarkItemsQuery;
