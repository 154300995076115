import { $ } from "~/zeus";
import { LocaleInputType } from "~/zeus";
import { mediaFragment } from "~/zeus/fragments";
import { typedGql } from "~/zeus/typedDocumentNode";

const updateUserMutation = typedGql("mutation")({
  updateUser: [
    {
      id: $("id", "String!"),
      data: {
        name: $("name", "String"),
        member: $("member", "mutationUserUpdate_MemberInput"),
        organiser: $("organiser", "mutationUserUpdate_OrganiserInput"),
      },
    },
    {
      id: true,
      email: true,
      name: true,
      roles: true,
      member: {
        avatar: [{}, mediaFragment],
        birthday: true,
        gender: true,
        categories: [
          {
            locale: LocaleInputType.zh,
          },
          {
            id: true,
            name: true,
            icon: [{}, mediaFragment],
          },
        ],
        phone: true,
        language: true,
        region: true,
      },
      organiser: {
        logo: [{}, mediaFragment],
        banner: [{}, mediaFragment],
        description: true,
        foundedIn: true,
        address: true,
        website: true,
        facebook: true,
        instagram: true,
      },
    },
  ],
});

export default updateUserMutation;
