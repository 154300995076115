import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";

const addDeviceMutation = typedGql("mutation")({
    AddDevice: [{
        id: $("id", "String!"),
        udid: $("udid", "String!"),
        pushToken: $("pushToken", "String!"),
    }, true]
});

export default addDeviceMutation;