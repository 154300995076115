import { $ } from "~/zeus";
import { LocaleInputType } from "~/zeus";
import { mediaFragment } from "~/zeus/fragments";
import { typedGql } from "~/zeus/typedDocumentNode";

const loginUserMutation = typedGql("mutation")({
  LoginUser: [
    {
      email: $("email", "String"),
      password: $("password", "String"),
      sha1Password: $("sha1Password", "String"),
    },
    {
      token: true,
      exp: true,
      user: {
        id: true,
        email: true,
        roles: true,
        name: true,
        organiser: {
          logo: [{}, mediaFragment],
          banner: [{}, mediaFragment],
          description: true,
          foundedIn: true,
          address: true,
          website: true,
          facebook: true,
          instagram: true,
          phone: true,
        },
        member: {
          avatar: [{}, mediaFragment],
          birthday: true,
          gender: true,
          phone: true,
          categories: [
            {
              locale: LocaleInputType.zh,
            },
            {
              id: true,
              name: true,
              icon: [{}, mediaFragment],
            },
          ],
          region: true,
          language: true,
        },
      },
    },
  ],
});

export default loginUserMutation;
