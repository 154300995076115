import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";

const reActivePasswordUserMutation = typedGql("mutation")({
  ReActivePasswordUser: [
    {
      username: $("username", "String!"),
      email: $("email", "String!"),
    },
    true,
  ],
});

export default reActivePasswordUserMutation;
