import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";

const bookmarkMutation = typedGql("mutation")({
    ItemBookmark: [{
        id: $("id", "String!"),
        relationTo: $("relationTo", "String!"),
    }, true]
});

export default bookmarkMutation;