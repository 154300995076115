import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";

const activeUserMutation = typedGql("mutation")({
  ActiveUser: [
    {
      email: $("email", "String!"),
    },
    true,
  ],
});

export default activeUserMutation;
