import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";

const resetPasswordUserMutation = typedGql("mutation")({
    resetPasswordUser: [{
        password: $("password", "String!"),
        token: $("token", "String!"),
    }, {
        token: true,
        user:{
            id: true
        }
    }]
});

export default resetPasswordUserMutation;