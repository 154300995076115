import { LocaleInputType } from "~/zeus";
import { mediaFragment } from "~/zeus/fragments";
import { typedGql } from "~/zeus/typedDocumentNode";

const meUserQuery = typedGql("query")({
  meUser: {
    token: true,
    exp: true,
    user: {
      id: true,
      email: true,
      name: true,
      roles: true,
      organiser: {
        logo: [{}, mediaFragment],
        banner: [{}, mediaFragment],
        description: true,
        foundedIn: true,
        address: true,
        website: true,
        facebook: true,
        instagram: true,
        phone: true,
      },
      member: {
        avatar: [
          {
            locale: LocaleInputType.zh,
          },
          mediaFragment,
        ],
        birthday: true,
        gender: true,
        categories: [
          {
            locale: LocaleInputType.zh,
          },
          {
            id: true,
            name: true,
            icon: [{}, mediaFragment],
          },
        ],
        phone: true,
        language: true,
        region: true,
      },
      sso: {
        googleId: true,
        facebookId: true,
        appleId: true,
      },
    },
  },
});

export default meUserQuery;
