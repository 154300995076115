import { useLazyQuery, useMutation } from "@apollo/client";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import CryptoJS from "crypto-js";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";

import type { UseDisclosureReturn } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";

import { useParams, useSearchParams } from "@remix-run/react";

import { useGlobalContext } from "./global";

import ItemBookmarkMutation from "~/api/mutation/ItemBookmarkMutation";
import ItemUnBookmarkMutation from "~/api/mutation/ItemUnBookmarkMutation";
import activeUserMutation from "~/api/mutation/activeUserMutation";
import addDeviceMutation from "~/api/mutation/addDeviceMutation";
import forgotPasswordUserMutation from "~/api/mutation/forgotPasswordUserMutation";
import loginUserMutation from "~/api/mutation/loginUserMutation";
import logoutUserMutation from "~/api/mutation/logoutUserMutation";
import reActivePasswordUserMutation from "~/api/mutation/reActivePasswordUserMutation";
import removeDeviceMutation from "~/api/mutation/removeDeviceMutation";
import resetPasswordUserMutation from "~/api/mutation/resetPasswordUserMutation";
import ssoLoginUserMutation from "~/api/mutation/ssoLoginUserMutation";
import updateUserMutation from "~/api/mutation/updateUserMutation";
import BookmarkItemsQuery from "~/api/query/BookmarkItemsQuery";
import meUserQuery from "~/api/query/meUserQuery";
import { useLocaleNavigate } from "~/libs/i18n";
import monitor from "~/utils/monitor";
import { User_roles } from "~/zeus";
import type { LocaleInputType, ModelTypes } from "~/zeus";

/** Provider */
type MemberContext = {
  user?: ModelTypes["User"] | null;
  setUser?: React.Dispatch<React.SetStateAction<ModelTypes["User"] | null>>;
  me: () => void;
  login: (info: {
    email: string;
    password: string;
    redirectUrl?: string;
  }) => void;
  ssoLogin: ({
    accessToken,
    type,
    appleJwtClaims,
  }: {
    accessToken: string;
    type: string;
    appleJwtClaims?: string;
  }) => void;
  update: (info: {
    id: string;
    input: ModelTypes["mutationUserUpdateInput"];
  }) => Promise<boolean> | void;
  forgotPassword: (info: {
    email: string;
    locale?: string;
    region?: string;
  }) => void;
  reActivePassword: (info: { email: string; username: string }) => void;
  resetPassword: (info: { password: string; token: string }) => void;
  activeUserByEmail: (info: { email: string }) => void;
  logout: (redirectUrl?: string) => void;
  bookmark: (info: { id: string; relationTo: string }) => void;
  unBookmark: (info: { id: string; relationTo: string }) => void;
  userBookmarkedIds?: string[] | [];
  processing: boolean;
  bookmarkLoading: boolean;
  errors: any;
  memberModalDisclosure: UseDisclosureReturn;
  isSSOLogin: boolean;
  isOrganiser: boolean;
};

export const memberContext = createContext<MemberContext | undefined>(
  undefined
);

export const MemberContextProvider = memberContext.Provider;

export const useMemberContext = () => {
  return useContext(memberContext) as MemberContext;
};

export const useMemberContextInit = (): MemberContext => {
  const navigate = useLocaleNavigate();
  const { locale } = useParams();
  const memberModalDisclosure = useDisclosure();
  const [user, setUser] = React.useState<ModelTypes["User"] | null>();
  const [userBookmarkedIds, setUserBookmarkedIds] = React.useState<
    string[] | []
  >([]);
  const { apolloClient, PUSH_TOKEN, UDID } = useGlobalContext();
  const [, setSP] = useSearchParams();

  const [getBookmarkItems] = useLazyQuery(BookmarkItemsQuery, {
    client: apolloClient,
    variables: {
      locale: locale as LocaleInputType,
      limit: 0,
    },
    fetchPolicy: "no-cache",
    onCompleted: ({ BookmarkItems }) => {
      const bookmarkedIds =
        BookmarkItems?.docs?.map(({ item }) => item?.value?.id) ?? [];
      setUserBookmarkedIds(bookmarkedIds as string[]);
    },
  });

  const [me] = useLazyQuery(meUserQuery, {
    client: apolloClient,
    onCompleted: ({ meUser }) => {
      if (meUser?.user) {
        setUser(meUser?.user as ModelTypes["User"]);
        getBookmarkItems();
      }
    },
    onError: (error) => {
      console.log(`error ${JSON.stringify(error, null, 2)}`);
    },
  });

  const [loginUser, { loading: loginUserLoading, error: loginUserError }] =
    useMutation(loginUserMutation, {
      client: apolloClient,
    });

  const [updateUser, { loading: updateUserLoading }] = useMutation(
    updateUserMutation,
    {
      client: apolloClient,
    }
  );

  const [logoutUser] = useMutation(logoutUserMutation, {
    client: apolloClient,
  });

  const [forgotPasswordUser] = useMutation(forgotPasswordUserMutation, {
    client: apolloClient,
  });

  const [reActivePasswordUser] = useMutation(reActivePasswordUserMutation, {
    client: apolloClient,
  });

  const [activeUser] = useMutation(activeUserMutation, {
    client: apolloClient,
  });

  const [resetPasswordUser, { loading: resetPasswordUserLoading }] =
    useMutation(resetPasswordUserMutation, {
      client: apolloClient,
    });

  const [itemBookmark, { loading: bookmarkLoading }] = useMutation(
    ItemBookmarkMutation,
    {
      client: apolloClient,
      fetchPolicy: "no-cache",
    }
  );

  const [itemUnBookmark, { loading: ubBookmarkLoading }] = useMutation(
    ItemUnBookmarkMutation,
    {
      client: apolloClient,
      fetchPolicy: "no-cache",
    }
  );

  const [AddDevice] = useMutation(addDeviceMutation, {
    client: apolloClient,
    onError: (error) => {
      console.log(`error ${JSON.stringify(error, null, 2)}`);
    },
  });

  const [RemoveDevice] = useMutation(removeDeviceMutation, {
    client: apolloClient,
  });

  const [ssoLoginUser] = useMutation(ssoLoginUserMutation, {
    client: apolloClient,
    onCompleted({ SSOLoginUser }) {
      // User not exist, pass hashed email to registration form
      if (!!SSOLoginUser?.token && !SSOLoginUser?.user?.id) {
        navigate(`/user/register?token=${SSOLoginUser?.token}`);
      } else if (SSOLoginUser?.user?.id) {
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 30);

        document.cookie =
          `server-token=${SSOLoginUser?.token}; expires=` +
          expiryDate.toUTCString() +
          "; path=/";

        setUser(SSOLoginUser?.user as unknown as ModelTypes["User"]);
        getBookmarkItems();
        navigate("/");
      }
    },
    onError(error) {
      console.error(error);
    },
  });

  const removeTokenFromDeviceList = useCallback(async () => {
    let data = {
      udid: UDID as string,
      pushToken: PUSH_TOKEN as string,
    };

    RemoveDevice({
      variables: data,
    });
  }, [PUSH_TOKEN, RemoveDevice, UDID]);

  const addTokenToDeviceList = useCallback(
    async ({ id }: { id: string }) => {
      let data = {
        id,
        udid: UDID as string,
        pushToken: PUSH_TOKEN as string,
      };

      AddDevice({
        variables: data,
      });
    },
    [AddDevice, PUSH_TOKEN, UDID]
  );

  const logout = useCallback(
    (redirectUrl?: string) => {
      logoutUser();
      setUser(null);
      document.cookie =
        "server-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      navigate(redirectUrl ?? "/");
      if (Capacitor.isNativePlatform()) {
        removeTokenFromDeviceList();
        PushNotifications.unregister();
      }
    },
    [logoutUser, navigate, removeTokenFromDeviceList]
  );

  const login = useCallback(
    (info: { email: string; password: string; redirectUrl?: string }): void => {
      const { redirectUrl, email, password } = info;
      loginUser({
        variables: {
          email,
          password,
          sha1Password: CryptoJS.SHA1(password).toString(),
        },
        onCompleted: ({ LoginUser }) => {
          if (LoginUser?.user?.id) {
            const expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + 30);

            document.cookie =
              `server-token=${LoginUser?.token}; expires=` +
              expiryDate.toUTCString() +
              "; path=/";

            setUser(LoginUser?.user as unknown as ModelTypes["User"]);
            getBookmarkItems();
            if (redirectUrl) {
              navigate(redirectUrl ?? "/");
            }
          }
        },
        onError: (error) => {
          console.log("error message", error);
        },
      });
    },
    [getBookmarkItems, loginUser, navigate]
  );

  const update = useCallback(
    async (info: {
      id: string;
      input: ModelTypes["mutationUserUpdateInput"];
    }): Promise<boolean> => {
      const { id, input } = info;

      const response = await updateUser({
        variables: { id, ...input },
        onCompleted: ({ updateUser }) => {
          if (updateUser?.id)
            setUser(updateUser as unknown as ModelTypes["User"]);
        },
      });

      const result = response?.data?.updateUser;

      return !!result?.id;
    },
    [updateUser]
  );

  const forgotPassword = useCallback(
    (info: { email: string }): void => {
      const { email } = info;
      forgotPasswordUser({
        variables: { email },
        onCompleted: ({ forgotPasswordUser }) => {
          if (forgotPasswordUser) {
            setSP((sp) => {
              sp.set("email_sent", "success");
              return sp;
            });
          }
        },
        onError: (error) => {
          console.log("error message", error);
        },
      });
    },
    [forgotPasswordUser, setSP]
  );

  const reActivePassword = useCallback(
    (info: { email: string; username: string }): void => {
      const { email, username } = info;
      reActivePasswordUser({
        variables: { email, username },
        onCompleted: ({ ReActivePasswordUser }) => {
          if (ReActivePasswordUser) {
            setSP((sp) => {
              sp.set("status", ReActivePasswordUser);
              return sp;
            });
          }
        },
      });
    },
    [reActivePasswordUser, setSP]
  );

  const activeUserByEmail = useCallback(
    (info: { email: string }): void => {
      const { email } = info;
      activeUser({
        variables: { email },
        onCompleted: ({ ActiveUser }) => {
          if (ActiveUser) {
            setSP((sp) => {
              sp.set("status", "success");
              return sp;
            });
          } else {
            setSP((sp) => {
              sp.set("status", "failed");
              return sp;
            });
          }
        },
      });
    },
    [activeUser, setSP]
  );

  const resetPassword = useCallback(
    (info: { password: string; token: string }): void => {
      const { password, token } = info;
      resetPasswordUser({
        variables: { password, token },
        onCompleted: ({ resetPasswordUser }) => {
          if (resetPasswordUser?.user) {
            setSP((sp) => {
              sp.set("status", "success");
              return sp;
            });
          }
        },
        onError: (error) => {
          setSP((sp) => {
            sp.set("status", "resetPasswordError");
            return sp;
          });
        },
      });
    },
    [resetPasswordUser, setSP]
  );

  const bookmark = useCallback(
    (info: { id: string; relationTo: string }): void => {
      const { id, relationTo } = info;
      itemBookmark({
        variables: { id, relationTo },
        onCompleted: ({ ItemBookmark }) => {
          getBookmarkItems();
        },
      });
    },
    [getBookmarkItems, itemBookmark]
  );

  const unBookmark = useCallback(
    (info: { id: string; relationTo: string }): void => {
      const { id, relationTo } = info;
      itemUnBookmark({
        variables: { id, relationTo },
        onCompleted: ({ ItemUnbookmark }) => {
          getBookmarkItems();
        },
      });
    },
    [getBookmarkItems, itemUnBookmark]
  );

  const ssoLogin = useCallback(
    async ({
      accessToken,
      type,
      appleJwtClaims,
    }: {
      accessToken: string;
      type: string;
      appleJwtClaims?: string;
    }) => {
      await ssoLoginUser({
        variables: {
          accessToken,
          type,
          appleJwtClaims,
        },
      });
    },
    [ssoLoginUser]
  );

  const isSSOLogin =
    !!user?.sso?.googleId || !!user?.sso?.facebookId || !!user?.sso?.appleId;
  const isOrganiser = !!user?.roles?.includes(User_roles.organiser);

  useEffect(() => {
    if (user) {
      monitor.setUser({
        id: user.id,
        email: user.email || user.displayEmail,
        username: user.name || user.legacyUsername,
      });
    } else {
      monitor.setUser(null);
    }
  }, [user]);

  useEffect(() => {
    if (user?.id) {
      if (Capacitor.isNativePlatform() && !!PUSH_TOKEN) {
        addTokenToDeviceList({ id: user?.id as string });
      }
    }
  }, [user?.id, PUSH_TOKEN, addTokenToDeviceList]);

  return {
    me,
    logout,
    login,
    ssoLogin,
    update,
    forgotPassword,
    reActivePassword,
    activeUserByEmail,
    resetPassword,
    bookmark,
    unBookmark,
    user,
    isOrganiser,
    userBookmarkedIds,
    memberModalDisclosure,
    processing:
      loginUserLoading || updateUserLoading || resetPasswordUserLoading,
    errors: {
      loginUserError,
    },
    bookmarkLoading: bookmarkLoading || ubBookmarkLoading,
    isSSOLogin,
  };
};
