import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";

const removeDeviceMutation = typedGql("mutation")({
  RemoveDevice: [
    {
      udid: $("udid", "String!"),
      pushToken: $("pushToken", "String"),
    },
    true,
  ],
});

export default removeDeviceMutation;
